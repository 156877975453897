import { List } from 'purify-ts/List';
import { assign, createMachine, Interpreter, spawn, SpawnedActorRef, State } from 'xstate';
import { choose } from 'xstate/lib/actions';
import { selectInitialSailings, selectSailing, updateSelectedAccommodation } from '../api';
import loaderMachine, { LoaderInterpreter, ResponseEvents, LoaderContext, LoaderEvent } from '../loader/loaderMachine';
import {
  Agreement,
  Catalog,
  CatalogErrors,
  Currency,
  ExtendedPassengerAndPet,
  ExtendedVehicle,
  Form,
  Language,
  PassengerInfo,
  PassengerType,
  SearchParams,
  Tariff,
  TripType,
  VehicleType,
} from '../types';
import { getSelected, legToIndex } from '../utils/sailingUtils';
import actions from './catalogActions';
import guards from './guards';

/**
 * Catalog context, actions, events, state and machine
 */

export interface CatalogContext {
  catalogs: Catalog[];
  errors: CatalogErrors;
  agreement?: Agreement;
  gtmState: { listing: boolean };
  loaderRef:
    | LoaderInterpreter
    | undefined
    | SpawnedActorRef<LoaderEvent, State<LoaderContext, LoaderEvent, any, { value: any; context: LoaderContext }>>;
  passengers: ExtendedPassengerAndPet[];
  path: string;
  searchParams: SearchParams;
  vehicles: ExtendedVehicle[];
  productPricesLoaded: boolean;
  ticketPricesLoaded: boolean;
  formsOnChangeTrip?: Form[];
}

export const catalogInitialContext: CatalogContext = {
  loaderRef: undefined,
  errors: [],
  gtmState: { listing: true },
  searchParams: {
    currency: Currency.EUR,
    language: Language.EN,
    starclub: false,
    forms: [],
    passengers: {
      ADULT: 0,
      CHILD: 0,
      INFANT: 0,
      JUNIOR: 0,
    },
    petCount: 0,
    type: TripType.RETURN,
    vehicles: [],
    legMeta: {
      cruiseNotAvailableShips: [],
    },
  },
  catalogs: [],
  passengers: [],
  vehicles: [],
  path: '/catalog',
  productPricesLoaded: false,
  ticketPricesLoaded: false,
};

export type INIT = { type: 'INIT' };
export type NEXT = { type: 'NEXT' };

export type ROOT = { type: '/catalog'; data: { popped: boolean } };
export type ONBOARD = { type: '/catalog/onboard'; data: { popped: boolean } };
export type INFO = { type: '/catalog/info'; data: { popped: boolean } };

export type TARIFF = {
  type: 'TARIFF';
  data: { leg: number; newTariff: Tariff; oldTariff: Tariff };
};

export type LOAD_SEARCH_RESULTS = { type: 'LOAD_SEARCH_RESULTS'; data: SearchParams };
export type LOAD_MORE_SAILINGS = { type: 'LOAD_MORE_SAILINGS'; data: any };
export type LOAD_CHANGE_TRIP_SAILINGS = { type: 'LOAD_CHANGE_TRIP_SAILINGS'; data: { forms: Form[] } };
export type RELOAD_MISSING_PRICES = { type: 'RELOAD_MISSING_PRICES'; data: any };
export type SELECT = { type: 'SELECT'; data: { sailingCode: string; index: number; loading?: boolean } };
export type LOAD_ACCOMMODATION_PRICES = {
  type: 'LOAD_ACCOMMODATION_PRICES';
  data: {
    sailingCode: string;
    leg: number;
    accommodations: { code: string; type: string }[];
    accommodationId: number;
  };
};

export type ADD_PASSENGER_LEG = { type: 'ADD_PASSENGER_LEG'; data: { id: number; leg: number } };
export type REMOVE_PASSENGER_LEG = {
  type: 'REMOVE_PASSENGER_LEG';
  data: { id: number; leg: number };
};
export type ADD_PASSENGER_INFO = {
  type: 'ADD_PASSENGER_INFO';
  data: { id: number } & { info?: Partial<PassengerInfo> };
};

//defaults to add one onboard product by product code
export type ADD_ONBOARD = { type: 'ADD_ONBOARD'; legs: number[]; code: string; amount?: number };
//defaults to remove one onboard product by product code
export type REMOVE_ONBOARD = {
  type: 'REMOVE_ONBOARD';
  legs: number[];
  code: string;
  amount?: number;
};

export type REMOVE_USER_SELECTED_MEALS = { type: 'REMOVE_USER_SELECTED_MEALS'; leg: number };

/**
 * Removes from other accommodations
 * Adds to accommodation
 * Validation against maxPeople & max pets
 */

export type ADD_ACCOMMODATION = {
  type: 'ADD_ACCOMMODATION';
  data: { leg: number; code: string; type: string; passengers?: number[]; addToIndex?: number };
};
export type REMOVE_ACCOMMODATION = {
  type: 'REMOVE_ACCOMMODATION';
  data: { leg: number; accommodationId: number };
};
export type REPLACE_ACCOMMODATION = {
  type: 'REPLACE_ACCOMMODATION';
  data: {
    leg: number;
    accommodationId: number;
    code: string;
    type: string;
    passengers?: number[];
  };
};

export type ADD_PASSENGER_TO_ACCOMMODATION = {
  type: 'ADD_PASSENGER_TO_ACCOMMODATION';
  data: {
    leg: number;
    accommodationId: number;
    passengerId: number;
  };
};

export type REMOVE_PASSENGER_FROM_ACCOMMODATION = {
  type: 'REMOVE_PASSENGER_FROM_ACCOMMODATION';
  data: {
    leg: number;
    accommodationId: number;
    passengerId: number;
  };
};

export type ADD_LEG_TO_VEHICLE = {
  type: 'ADD_LEG_TO_VEHICLE';
  data: {
    leg: number;
    vehicleId: number;
  };
};
export type REMOVE_LEG_FROM_VEHICLE = {
  type: 'REMOVE_LEG_FROM_VEHICLE';
  data: {
    leg: number;
    vehicleId: number;
  };
};
export type EDIT_VEHICLE = {
  type: 'EDIT_VEHICLE';
  data: {
    id: number;
    info: {
      type?: VehicleType;
      lengthCm?: number | null;
      heightCm?: number | null;
      registrationId?: string | null;
      make?: string | null;
      model?: string | null;
      fuelType?: string | null;
    };
  };
};

export type CLEAR_CART = { type: 'CLEAR_CART' };
export type REMOVE_ONBOARD_PRODUCTS_FROM_CART = { type: 'REMOVE_ONBOARD_PRODUCTS_FROM_CART' };
export type ADD_ONBOARD_PRODUCTS_TO_CART = { type: 'ADD_ONBOARD_PRODUCTS_TO_CART' };
export type ADD_TRIP_TO_CART = { type: 'ADD_TRIP_TO_CART' };
export type ON_WIFI_OPEN = { type: 'ON_WIFI_OPEN'; data: { sailingCode: string } };
export type ON_CELEBRATION_PACKAGES_OPEN = {
  type: 'ON_CELEBRATION_PACKAGES_OPEN';
  data: { sailingCode: string };
};
export type ON_LOUNGE_PACKAGES_OPEN = {
  type: 'ON_LOUNGE_PACKAGES_OPEN';
  data: { sailingCode: string; passengerTypes: PassengerType[] };
};
export type ON_ONBOARD_COUPONS_OPEN = {
  type: 'ON_ONBOARD_COUPONS_OPEN';
  data: { sailingCode: string };
};
export type ON_WELLNESS_SERVICES_OPEN = {
  type: 'ON_WELLNESS_SERVICES_OPEN';
  data: { sailingCode: string };
};
export type ON_MEAL_OPEN = {
  type: 'ON_MEAL_OPEN';
  data: { sailingCode: string; passengerTypes: PassengerType[] };
};
export type ON_NIGHT_OPEN = {
  type: 'ON_NIGHT_OPEN';
  data: { sailingCode: string; passengerTypes: PassengerType[] };
};

type GlobalEvents = NEXT | ROOT | ONBOARD | INFO;

type ListingEvents =
  | INIT
  | LOAD_SEARCH_RESULTS
  | SELECT
  | LOAD_MORE_SAILINGS
  | LOAD_CHANGE_TRIP_SAILINGS
  | RELOAD_MISSING_PRICES
  | LOAD_ACCOMMODATION_PRICES
  | ADD_PASSENGER_LEG
  | REMOVE_PASSENGER_LEG
  | ADD_ACCOMMODATION
  | REMOVE_ACCOMMODATION
  | REPLACE_ACCOMMODATION
  | ADD_PASSENGER_TO_ACCOMMODATION
  | REMOVE_PASSENGER_FROM_ACCOMMODATION
  | ADD_LEG_TO_VEHICLE
  | REMOVE_LEG_FROM_VEHICLE
  | EDIT_VEHICLE
  | TARIFF;

type OnboardEvents = ADD_ONBOARD | REMOVE_ONBOARD | REMOVE_USER_SELECTED_MEALS;
type InfoEvents = ADD_PASSENGER_INFO;
type AnalyticsEvents =
  | CLEAR_CART
  | REMOVE_ONBOARD_PRODUCTS_FROM_CART
  | ON_WIFI_OPEN
  | ON_CELEBRATION_PACKAGES_OPEN
  | ON_LOUNGE_PACKAGES_OPEN
  | ON_ONBOARD_COUPONS_OPEN
  | ON_MEAL_OPEN
  | ON_NIGHT_OPEN
  | ADD_ONBOARD_PRODUCTS_TO_CART
  | ADD_TRIP_TO_CART
  | ON_WELLNESS_SERVICES_OPEN;

export type CatalogEvent = GlobalEvents | ListingEvents | OnboardEvents | InfoEvents | ResponseEvents | AnalyticsEvents;

export interface CatalogStateSchema {
  states: {
    listing: {
      states: {
        boot: {};
        idle: {};
        loadSearchResults: {};
        loadInitialProducts: {};
        loadInitialPrices: {};
        selectInitialSailings: {};
        loadChangeTripSailings: {};
        select: {};
        updateSelectedAccommodation: {};
      };
    };
    onboard: {};
    info: {};
    payment: {};
  };
}

export type CatalogTypestates = {
  value:
    | 'listing'
    | 'listing.boot'
    | 'listing.idle'
    | 'listing.loadSearchResults'
    | 'listing.loadInitialProducts'
    | 'listing.loadInitialPrices'
    | 'listing.selectInitialSailings'
    | 'listing.loadChangeTripSailings'
    | 'listing.select'
    | 'listing.updateSelectedAccommodation'
    | 'onboard'
    | 'info'
    | 'payment';
  context: CatalogContext;
};

export type CatalogInterpreter = Interpreter<CatalogContext, CatalogState, CatalogEvent, CatalogTypestates>;
export type CatalogState = State<CatalogContext, CatalogEvent, CatalogStateSchema>;

export const loaderId = 'loader';
export default createMachine<CatalogContext, CatalogEvent, CatalogTypestates>(
  {
    id: 'catalog',
    initial: 'listing',
    context: catalogInitialContext,
    states: {
      listing: {
        id: 'listing',
        initial: 'boot',
        //@ts-ignore
        entry: [
          assign<CatalogContext, CatalogEvent>({
            loaderRef: (context) => context.loaderRef || spawn(loaderMachine, loaderId),
            path: '/catalog',
            gtmState: { listing: true },
          }),
          'updateHistory',
        ],
        states: {
          boot: {
            always: [{ target: 'loadSearchResults', cond: 'loadSearchResultsOnBoot' }, { target: 'idle' }],
          },
          idle: {
            //@ts-ignore
            entry: [assign<CatalogContext>({ path: '/catalog' })],
            on: {
              SELECT: {
                actions: [],
                target: 'select',
                cond: ({ catalogs }: CatalogContext, { data: { index, sailingCode, loading } }) =>
                  getSelected(catalogs[index]).mapOrDefault((_) => _.sailingCode !== sailingCode, false),
              },
              LOAD_MORE_SAILINGS: {
                actions: ['onLoadMoreSailings'],
              },
              LOAD_CHANGE_TRIP_SAILINGS: {
                actions: [
                  assign<CatalogContext, LOAD_CHANGE_TRIP_SAILINGS>({
                    formsOnChangeTrip: (_: CatalogContext, { data: { forms } }) => forms,
                  }),
                  'onTicketPricesRemoved',
                  'onProductPricesLoaded',
                ],
                target: 'loadChangeTripSailings',
              },
              RELOAD_MISSING_PRICES: {
                actions: ['reloadMissingPrices', 'setSpinnerOn'],
              },
              REMOVE_PASSENGER_LEG: {
                actions: ['removeLeg', 'loadSelectedPricing'],
              },
              ADD_PASSENGER_LEG: {
                actions: ['addLeg', 'loadSelectedPricing'],
              },
              ADD_ACCOMMODATION: {
                actions: ['addAccommodation', 'loadSelectedPricing'],
              },
              REMOVE_ACCOMMODATION: {
                actions: ['removeAccommodation', 'loadSelectedPricing'],
              },
              REPLACE_ACCOMMODATION: {
                actions: ['onTicketPricesRemoved', 'replaceAccommodation', 'loadSelectedPricing'],
              },
              ADD_PASSENGER_TO_ACCOMMODATION: {
                actions: ['addPassengerToAccommodation'],
              },
              REMOVE_PASSENGER_FROM_ACCOMMODATION: {
                actions: ['removePassengerFromAccommodation'],
              },
              LOAD_ACCOMMODATION_PRICES: {
                actions: ['loadAccommodationPrices'],
              },
              ADD_LEG_TO_VEHICLE: {
                actions: ['addLegToVehicle', 'loadSelectedPricing'],
              },
              REMOVE_LEG_FROM_VEHICLE: {
                actions: ['removeLegFromVehicle', 'loadSelectedPricing'],
              },
              EDIT_VEHICLE: {
                actions: ['editVehicle'],
              },
              TARIFF: {
                actions: ['setTariff', 'loadSelectedPricing'],
                cond: ({ catalogs }, { data }) =>
                  // Do not load prices when tariff does not change.
                  List.find((_) => _.index === legToIndex(data.leg), catalogs)
                    .chain(getSelected)
                    .mapOrDefault((_) => _.tariff !== data.newTariff, false),
              },
            },
          },
          loadChangeTripSailings: {
            id: 'loadChangeTripSailings',
            always: [
              {
                actions: ['onLoadChangeTripSailings', 'createPassengers', 'createVehicles', 'search'],
                target: 'idle',
              },
            ],
          },
          loadSearchResults: {
            id: 'loadSearchResults',
            always: [
              {
                actions: ['onSearchStart', 'createPassengers', 'createVehicles', 'search'],
                target: 'idle',
              },
            ],
          },
          loadInitialProducts: {
            id: 'loadInitialProducts',
            always: [
              {
                actions: ['loadInitialProducts'],
                target: 'idle',
              },
            ],
          },
          loadInitialPrices: {
            id: 'loadInitialPrices',
            always: [
              {
                actions: ['loadInitialPricing'],
                target: 'idle',
              },
            ],
          },
          selectInitialSailings: {
            id: 'selectInitialSailings',
            entry: [],
            invoke: {
              id: 'selectInitialSailings',
              src: selectInitialSailings,
              onDone: {
                target: 'updateSelectedAccommodation',
                actions: choose([
                  {
                    cond: (_: CatalogContext, { data }: any) => data.type === 'DONE_CATALOG_PRODUCTS',
                    actions: ['updateSelected'],
                  },
                  {
                    actions: ['updateSelected', 'onTicketPricesLoaded'],
                  },
                ]),
              },
              onError: {
                actions: [(_, evt) => console.error('selectInitial', evt)],
                target: 'idle',
              },
            },
          },
          select: {
            id: 'select',
            entry: ['setSpinnerOn'],
            invoke: {
              id: 'selectSailing',
              src: selectSailing,
              onDone: {
                actions: ['updateSelected'],
                target: 'updateSelectedAccommodation',
              },
              onError: {
                actions: [(_, evt) => console.error('select', evt)],
                target: 'idle',
              },
            },
          },
          updateSelectedAccommodation: {
            id: 'updateSelectedAccommodation',
            invoke: {
              id: 'updateSelectedAccommodation',
              src: updateSelectedAccommodation,
              onDone: [
                {
                  cond: (_: CatalogContext, { data }: any) => data.type === 'DONE_CATALOG_PRODUCTS',
                  target: 'loadInitialPrices',
                  actions: ['updateSelected'],
                },
                {
                  target: 'idle',
                  actions: ['updateSelected', 'loadProductPrices', 'loadSelectedPricing'], // Will run max 3 queries, unless cached
                },
              ],
              onError: {
                actions: [(_, evt) => console.error('updateSelectedAccommodation', evt)],
                target: 'idle',
              },
            },
          },
        },
        on: {
          NEXT: {
            target: 'onboard',
            cond: ({ catalogs }) =>
              catalogs.every((catalog) => getSelected(catalog).mapOrDefault((_) => typeof _.price === 'object', false)),
            actions: ['gtmAddTripToCart'],
          },

          /**
           * LOADER RESPONSE EVENTS
           * Listing only
           */
          LOADED_ACCOMMODATION_PRICES: [
            {
              target: 'listing.idle',
              actions: ['onLoadAccommodationPrices'],
            },
          ],
          LOADED_CATALOGS: [
            {
              target: 'listing.loadInitialProducts',
              actions: ['onSearchDone'],
            },
          ],
          LOADED_CATALOG_PRODUCTS: {
            actions: ['onLoadInitialProducts'],
          },
          DONE_CATALOG_PRODUCTS: {
            target: 'listing.selectInitialSailings',
          },
          LOADED_CATALOG_PRICES: {
            actions: ['onLoadTimetables'],
          },
          DONE_CATALOG_PRICES: {
            actions: ['onLoadInitialPricing'],
            target: 'listing.selectInitialSailings',
          },
          LOADED_PRODUCT_PRICES: {
            actions: ['onLoadProductPrices', 'onProductPricesLoaded'],
          },
          DONE_PRODUCT_PRICES: {
            actions: ['onProductPricesLoaded'],
          },
          ON_RELOAD_MISSING: {
            actions: ['onReloadMissingPrices'],
          },
          DONE_RELOAD_MISSING: {
            actions: [() => console.log('CatalogMachine: DONE_RELOAD_MISSING')],
          },
        },
      },
      onboard: {
        id: 'onboard',
        entry: [
          assign<CatalogContext, CatalogEvent>({
            loaderRef: (context) => context.loaderRef || spawn(loaderMachine, loaderId),
            path: '/catalog/onboard',
          }),
          'updateHistory',
        ],
        on: {
          NEXT: {
            target: 'info',
            actions: ['gtmAddOnboardToCart'],
          },
          ON_MEAL_OPEN: {
            actions: ['gtmSendMealImpressions'],
          },
          ON_WIFI_OPEN: {
            actions: ['gtmSendWifiImpressions'],
          },
          ON_CELEBRATION_PACKAGES_OPEN: {
            actions: ['gtmSendCelebrationPackagesImpressions'],
          },
          ON_LOUNGE_PACKAGES_OPEN: {
            actions: ['gtmSendLoungePackagesImpressions'],
          },
          ON_ONBOARD_COUPONS_OPEN: {
            actions: ['gtmSendOnboardCouponsImpressions'],
          },
          ON_WELLNESS_SERVICES_OPEN: {
            actions: ['gtmSendWellnessServicesImpressions'],
          },
          ON_NIGHT_OPEN: {
            actions: ['gtmSendNightImpressions'],
          },
        },
      },
      info: {
        id: 'info',
        entry: [
          assign<CatalogContext, CatalogEvent>({
            loaderRef: (context) => context.loaderRef || spawn(loaderMachine, loaderId),
            path: '/catalog/info',
          }),
          'updateHistory',
          'gtmCheckoutStart',
        ],
        on: {
          NEXT: [
            {
              target: 'payment',
              cond: 'infoGuard',
            },
            {
              actions: 'onInfoError',
            },
          ],
          ADD_PASSENGER_INFO: [
            {
              actions: ['addInfo'],
            },
          ],
          EDIT_VEHICLE: {
            actions: ['editVehicle'],
          },
        },
      },
      payment: {
        id: 'payment',
        type: 'final',
        entry: 'payment',
      },
    },
    on: {
      '/catalog': {
        target: 'listing',
      },
      '/catalog/onboard': {
        target: 'onboard',
      },
      '/catalog/info': {
        target: 'info',
      },
      /**
       * Global analytics events
       */
      CLEAR_CART: {
        actions: 'gtmClearCart',
      },
      REMOVE_ONBOARD_PRODUCTS_FROM_CART: {
        actions: 'gtmRemoveOnboardProductsFromCart',
      },
      ADD_TRIP_TO_CART: {
        actions: 'gtmAddTripToCart',
      },
      ADD_ONBOARD_PRODUCTS_TO_CART: {
        actions: 'gtmAddOnboardToCart',
      },
      /**
       * LOADER RESPONSE EVENTS
       * Global
       */
      RELOADED_SELECTED: {
        actions: choose([
          {
            cond: ({ path, gtmState }: CatalogContext) => path === '/catalog' && gtmState.listing,
            actions: ['onLoadSelectedPricing', 'gtmSendListingImpressions', 'onTicketPricesLoaded'],
          },
          {
            actions: ['onLoadSelectedPricing', 'onTicketPricesLoaded'],
          },
        ]),
      },
      ADD_ONBOARD: {
        actions: ['addOnboard', 'loadSelectedPricing'],
      },
      REMOVE_ONBOARD: {
        actions: ['removeOnboard', 'loadSelectedPricing'],
      },
      REMOVE_USER_SELECTED_MEALS: {
        actions: ['removeUserSelectedMeals'],
      },
    },
  },
  {
    // @ts-ignore
    actions,
    guards,
  }
);
